import { Field, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { logInSchema } from '../validations/LogInScheme';
import hB2nNiR from '../images/hB2nNiR.jpg';

// utils
import SEO from '../utils/SEO';

import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';

const LogIn = () => {
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleGoogleLogIn,
    handleLogIn,
    authUser,
    setAuthLoading,
    handleNavigation,
  } = useAuth();

  const googleLogin = async () => {
    const { error } = await handleGoogleLogIn();
    if (error) {
      toast({
        title: 'Error on Google Log In',
        description: error,
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleSubmit = async values => {
    const { error, errorMessage } = await handleLogIn(
      values.email,
      values.password
    );
    if (error) {
      if (errorMessage.toString().includes('auth/user-not-found')) {
        toast({
          title: 'User not found',
          description: 'Please re-check your login credentials',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      } else if (errorMessage.toString().includes('auth/wrong-password')) {
        toast({
          title: 'Wrong Password',
          description: 'Re-enter the correct password',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        toast({
          title: 'Error on Log In',
          description: errorMessage,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        // navigate('/');
        handleNavigation(authUser?.data);
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setIsDesktopScreen(isDesktop);
  }, [isDesktop]);

  return (
    <Fragment>
      <SEO
        title={`Log In`}
        description={`Log in page for Utopia Homes. Log in to your account to manage your properties and applications.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Grid h="100vh" templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={isDesktopScreen ? 3 : 6}>
          <Container maxW="container.sm" py="8">
            <Link href="https://www.utopiahomes.us/">
              <Image
                objectFit="cover"
                w="150px"
                src="https://i.imgur.com/avkbaJm.png"
                alt="Utopia Logo"
              />
            </Link>
          </Container>
          <Container maxW="md" py="8">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={logInSchema}
              onSubmit={async values => {
                handleSubmit(values);
              }}
            >
              {formik => (
                <form onSubmit={formik.handleSubmit}>
                  <Stack spacing="7">
                    <Box>
                      <Heading mb="2">Log into Utopia Homes</Heading>
                      <Text fontSize="md" as="b" color="gray.600">
                        Enter your email and password
                      </Text>
                    </Box>
                    <Stack spacing="7">
                      <Stack spacing="4">
                        <FormControl
                          isInvalid={
                            formik.errors.email && formik.touched.email
                          }
                        >
                          <FormLabel color="gray">Email</FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="email"
                            name="email"
                            type="email"
                            disabled={false}
                          />
                          <FormErrorMessage>
                            {formik.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            formik.errors.password && formik.touched.password
                          }
                        >
                          <FormLabel color="gray">Password</FormLabel>
                          <Field
                            as={Input}
                            borderWidth="2px"
                            size="lg"
                            id="password"
                            name="password"
                            type="password"
                            disabled={false}
                          />
                          <FormErrorMessage>
                            {formik.errors.password}
                          </FormErrorMessage>
                        </FormControl>
                      </Stack>

                      <Stack spacing="4">
                        <Button size="lg" type="submit">
                          Log In
                        </Button>
                        <Flex justify="right">
                          <Link
                            fontSize="md"
                            as="b"
                            color="blue.500"
                            onClick={() => {
                              navigate('/account/reset_password');
                            }}
                          >
                            Forgot Password
                          </Link>
                        </Flex>
                      </Stack>
                    </Stack>
                    {/* <Text fontSize="md" as="b" color="gray.600">
                      Or log in with your social account
                    </Text>
                    <Stack spacing="4">
                      <Button size="lg" colorScheme="red" onClick={googleLogin}>
                        Log In with Google
                      </Button>
                    </Stack> */}
                    <Flex align="center" justify="center">
                      <Text fontSize="md" as="b" mr="3">
                        Don't have an account?
                      </Text>
                      <Link
                        fontSize="md"
                        as="b"
                        color="blue.500"
                        onClick={() => {
                          navigate('/accounts');
                        }}
                      >
                        Sign Up
                      </Link>
                    </Flex>
                  </Stack>
                </form>
              )}
            </Formik>
          </Container>
        </GridItem>
        {isDesktopScreen && (
          <GridItem colSpan={3}>
            <Image src={hB2nNiR} objectFit="cover" h="100vh" />
          </GridItem>
        )}
      </Grid>
    </Fragment>
  );
};

export default LogIn;
