import { Icon } from '@chakra-ui/react';
import {
  TbArrowBarDown,
  TbCheck,
  TbChecks,
  TbClock,
  TbClock2,
  TbFileImport,
  TbFolder,
  TbFriends,
  TbHome,
  TbUserExclamation,
} from 'react-icons/tb';

import {
  AiOutlineReconciliation,
  AiOutlineQuestionCircle,
} from 'react-icons/ai';
import { BsFileEarmarkCheck, BsListUl, BsPersonCheck } from 'react-icons/bs';
import { CgFileAdd, CgPlayPauseR } from 'react-icons/cg';
import { FaUserClock, FaHome } from 'react-icons/fa';
import { FcInspection, FcInvite } from 'react-icons/fc';
import { FiHelpCircle, FiMail } from 'react-icons/fi';
import { GiCheckMark } from 'react-icons/gi';
import {
  RiGift2Line,
  RiUserUnfollowLine,
  RiUserFollowLine,
  RiUserAddLine,
  RiUser3Line,
} from 'react-icons/ri';
import { TfiLayoutListThumb } from 'react-icons/tfi';
import { TiWarningOutline } from 'react-icons/ti';
import { IoIosGlobe } from 'react-icons/io';
import { SiCodereview } from 'react-icons/si';

const LinkItems = [
  {
    name: 'Home',
    icon: <Icon as={TbHome} width="25px" height="20px" color={'inherit'} />,
    path: '/agents/home',
    subRoutes: [],
  },
  {
    name: 'All Clients',
    icon: <Icon as={TbFriends} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/clients',
    subRoutes: [],
  },

  {
    name: 'Invite & Earn',
    icon: <Icon as={FcInvite} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/invite',
    subRoutes: [],
  },

  {
    name: 'Upload Properties',
    icon: <Icon as={FaHome} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/properties',
    subRoutes: [],
  },

  {
    name: 'Applications',
    icon: <Icon as={TbFolder} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/applications',
    subRoutes: [
      {
        name: 'Invited',
        icon: (
          <Icon
            as={TbArrowBarDown}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/applications/invited',
      },
      {
        name: 'Application Started',
        icon: (
          <Icon as={TbClock} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/applications/application_started',
      },
      {
        name: 'Prequalified',
        icon: (
          <Icon as={TbCheck} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/applications/prequalified',
      },

      {
        name: 'More Info Required',
        icon: (
          <Icon
            as={AiOutlineQuestionCircle}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/applications/more_info',
      },

      {
        name: 'In-Review',
        icon: (
          <Icon
            as={SiCodereview}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/applications/inreview',
      },

      {
        name: 'Conditionally Approved',
        icon: (
          <Icon as={TbFolder} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/applications/conditionally_approved',
      },
      {
        name: 'Fully Approved',
        icon: (
          <Icon as={TbChecks} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/applications/fully_approved',
      },
    ],
  },
  {
    name: 'Offers',
    icon: (
      <Icon as={TbFileImport} width="20px" height="20px" color={'inherit'} />
    ),
    path: '/agents/offers',
    subRoutes: [
      {
        name: 'Initiate Offer',
        icon: (
          <Icon as={CgFileAdd} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/initiate_offer',
      },

      {
        name: 'Initiated Offers',
        icon: (
          <Icon
            as={TfiLayoutListThumb}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/offers/initiated',
      },

      {
        name: 'Offer Extended',
        icon: (
          <Icon as={FaUserClock} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/offers/offer_extended',
      },

      {
        name: 'Offer Accepted',
        icon: (
          <Icon
            as={BsFileEarmarkCheck}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/offers/offer_accepted',
      },
    ],
  },

  {
    name: 'Closings',
    icon: <Icon as={TbClock2} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/closings',
    subRoutes: [
      {
        name: 'Inspection Complete',
        icon: (
          <Icon
            as={FcInspection}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/closings/inspection_complete',
      },

      {
        name: 'Preclosing Complete',
        icon: (
          <Icon
            as={BsPersonCheck}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/closings/preclosing_complete',
      },
      {
        name: 'Offer Closed',
        icon: (
          <Icon as={GiCheckMark} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/closings/offer_closed',
      },
    ],
  },

  {
    name: 'Inactive',
    icon: (
      <Icon
        as={TbUserExclamation}
        width="20px"
        height="20px"
        color={'inherit'}
      />
    ),
    path: '/agents/inactive',
    subRoutes: [
      {
        name: 'Inactive',
        icon: (
          <Icon
            as={RiUserUnfollowLine}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/inactive',
      },
      {
        name: 'Rejected',
        icon: (
          <Icon
            as={TiWarningOutline}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/rejected',
      },
      {
        name: 'Paused',
        icon: (
          <Icon
            as={CgPlayPauseR}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/paused',
      },
    ],
  },

  {
    name: 'Agent Invites',
    icon: <Icon as={BsListUl} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/invites',
    subRoutes: [],
  },
  {
    name: 'Landlord Invites',
    icon: <Icon as={BsListUl} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/landlord/invites',
    subRoutes: [],
  },
  {
    name: 'Landlord Properties',
    icon: <Icon as={BsListUl} width="20px" height="20px" color={'inherit'} />,
    path: '/agents/landlord/properties',
    subRoutes: [
      {
        name: 'Started',
        icon: (
          <Icon as={RiUser3Line} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/landlord/properties/started',
      },
      {
        name: 'In Review',
        icon: (
          <Icon
            as={RiUserAddLine}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/landlord/properties/in_review',
      },
      {
        name: 'Rejected',
        icon: (
          <Icon
            as={RiUserUnfollowLine}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/landlord/properties/rejected',
      },
      {
        name: 'Accepted',
        icon: (
          <Icon
            as={RiUserFollowLine}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/landlord/properties/accepted',
      },
    ],
  },
  {
    name: 'Incentives',
    icon: (
      <Icon as={RiGift2Line} width="20px" height="20px" color={'inherit'} />
    ),
    path: '/agents/incentives',
    subRoutes: [],
  },
  {
    name: 'Resources',
    icon: (
      <Icon
        as={AiOutlineReconciliation}
        width="20px"
        height="20px"
        color={'inherit'}
      />
    ),
    path: '/agents/resources',
    subRoutes: [
      {
        name: 'Client Marketing',
        icon: (
          <Icon as={IoIosGlobe} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/marketing',
      },

      {
        name: 'Landlord Marketing',
        icon: (
          <Icon as={IoIosGlobe} width="20px" height="20px" color={'inherit'} />
        ),
        path: '/agents/landlord/marketing',
      },

      {
        name: 'FAQs',
        icon: (
          <Icon
            as={FiHelpCircle}
            width="20px"
            height="20px"
            color={'inherit'}
          />
        ),
        path: '/agents/faq',
      },

      {
        name: 'Contact Us',
        icon: <Icon as={FiMail} width="20px" height="20px" color={'inherit'} />,
        path: '/agents/contact_us',
      },
    ],
  },
];

export default LinkItems;
