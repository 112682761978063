import React, { Fragment, useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';

// services
import {
  alertAdminOfListingInterestUnregisteredUser,
  alertAdminOfListingInterestPrequalifiedUser,
  alertHomebuyerOfListingInterest,
} from '../../utils/services/homebuyer.service';

// context
import { useHomeBuyerAuth } from '../../context/HomebuyerContext';
import { useAuth } from '../../context/AuthContext';
import { useLandlord } from '../../context/LandlordContext';

// components
import Footer from './Footer';
import TextRichView from '../TextRich/TextRichView';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Image,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Input,
  Spinner,
  IconButton,
  Collapse,
} from '@chakra-ui/react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@chakra-ui/icons';

const ListingSection = ({ id }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const toast = useToast();

  const { applicationData, addInterestToListing } = useHomeBuyerAuth();
  const { authUser } = useAuth();
  const {
    getPropertyById_Full,
    getPropertyById_Truncated,
    updatePropertyView,
  } = useLandlord();

  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [buttonData, setButtonData] = useState({
    text: 'Get Pre-qualified',
    color: 'blue',
    disabled: false,
    handleClick: async () => {
      navigate(`/signup/homebuyer?listingInterest=${id}`);
    },
  });

  const [propertyData, setPropertyData] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [homePrice, setHomePrice] = useState(null);
  const [rent, setRent] = useState(null);
  const [show, setShow] = useState(false);

  const [alertData, setAlertData] = useState({
    title: 'Alert',
    description: 'Alert description',
  });

  useEffect(() => {
    const fetchData = async () => {
      await updatePropertyView(id);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const property = await getPropertyById_Truncated(id);
        setPropertyData(property?.data);
        setPropertyImages(property?.images);
        setHomePrice(property?.data?.stepThree[0]?.answer);
        setRent(property?.data?.stepOne[3]?.answer);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const property = await getPropertyById_Full(id);
        setPropertyData(property?.data);
        setPropertyImages(property?.images);
        setHomePrice(property?.data?.stepThree[0]?.answer);
        setRent(property?.data?.stepOne[3]?.answer);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          authUser &&
          authUser?.data?.type === 'home_owner' &&
          authUser?.data?.applicationCompleted === true
        ) {
          let prequalifiedBudget = 0;
          prequalifiedBudget =
            applicationData?.stepFive[0].answer === 'yes'
              ? Math.min(
                  4.358 *
                    (parseInt(applicationData.stepSix[0]?.answer) +
                      parseInt(applicationData.stepSix[4]?.answer)) *
                    12,
                  498990
                )
              : Math.min(
                  4.358 * parseInt(applicationData?.stepSix[0]?.answer) * 12,
                  498990
                );
          prequalifiedBudget = parseInt(
            isNaN(prequalifiedBudget) ? 0 : prequalifiedBudget
          );

          let qualifiedBudget = authUser?.data?.qualifiedAmount
            ? authUser?.data?.qualifiedAmount
            : 0;

          if (qualifiedBudget >= homePrice || prequalifiedBudget >= homePrice) {
            setButtonData({
              text: 'Get Home',
              color: 'blue',
              disabled: false,
              handleClick: async () => {
                // save to firebase
                await addInterestToListing(id);

                // send email to admin
                await alertAdminOfListingInterestPrequalifiedUser({
                  userId: authUser?.uid,
                  propertyId: id,
                });
                await alertHomebuyerOfListingInterest({
                  userId: authUser?.uid,
                  propertyId: id,
                });

                toast({
                  title: 'Notification sent!',
                  description:
                    'Thanks for your interest in this Utopia. We will circle back with you very shortly.',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                  position: 'top-right',
                });
              },
            });
          } else {
            setButtonData({
              text: 'Home is out of your budget',
              color: 'gray',
              disabled: true,
              handleClick: async () => {},
            });
          }
        } else if (
          authUser &&
          (authUser?.data?.type === 'agent' ||
            authUser?.data?.type === 'landlord')
        ) {
          setButtonData({
            text: 'Sign In as a Homebuyer',
            color: 'blue',
            disabled: false,
            handleClick: async () => {
              navigate(`/`);
            },
          });
        } else {
          setButtonData({
            text: 'Get Pre-qualified',
            color: 'blue',
            disabled: false,
            handleClick: async () => {
              // await addInterestToListing(id);

              // toast({
              //   title: 'Notification sent!',
              //   description:
              //     "We've sent a notification to the Utopia team of your interest in this home.",
              //   status: 'success',
              //   duration: 9000,
              //   isClosable: true,
              //   position: 'top-right',
              // });

              navigate(`/signup/homebuyer?listingInterest=${id}`);
            },
          });
        }
      } catch (err) {
        console.log('Error on useEffect(): ', err);
      }
    };

    fetchData().catch(console.error);
  }, [applicationData]);

  const handleToggle = () => setShow(!show);

  return (
    <Fragment>
      <Flex bg="gray.100" direction="column" flex={1} overflow="auto">
        <Container maxW="container.xl" mb="12">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Box mt="4" bg="gray.300">
                <Carousel
                  responsive={responsive}
                  infinite={true}
                  itemClass="carousel-item-padding"
                  customLeftArrow={
                    <IconButton
                      position="absolute"
                      left={0}
                      top="50%"
                      transform="translateY(-50%)"
                      size="lg"
                      borderRadius="full"
                      bg="white"
                      _hover={{ bg: 'gray.100' }}
                      icon={<ChevronLeftIcon boxSize={8} />}
                    />
                  }
                  customRightArrow={
                    <IconButton
                      position="absolute"
                      right={0}
                      top="50%"
                      transform="translateY(-50%)"
                      size="lg"
                      borderRadius="full"
                      bg="white"
                      _hover={{ bg: 'gray.100' }}
                      icon={<ChevronRightIcon boxSize={8} />}
                    />
                  }
                >
                  {propertyImages.map((image, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        setSelectedImage(image);
                        onOpen();
                      }}
                      _hover={{ cursor: 'pointer' }}
                      position="relative"
                      paddingTop="75%"
                      mx="2"
                    >
                      <Image
                        src={image?.imageUrl}
                        alt={`Property view ${index + 1}`}
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        width="100%"
                        height="100%"
                        objectFit="cover"
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>

              <Box pt="5">
                <Grid templateColumns="repeat(7, 1fr)" gap="9">
                  <GridItem colSpan={{ base: 7, md: 5 }}>
                    <Stack
                      divider={<StackDivider borderColor="gray.300" />}
                      spacing="5"
                    >
                      <Stack spacing="3">
                        <Text fontSize="3xl" fontWeight="600">
                          {propertyData?.stepTwo[3]?.answer}
                        </Text>
                        <Text fontSize="xl" fontWeight="500">
                          {propertyData?.stepTwo[1]?.answer},{' '}
                          {propertyData?.stepTwo[0]?.answer}
                        </Text>
                        <Stack
                          direction="row"
                          divider={<StackDivider borderColor="gray.300" />}
                          fontSize="xl"
                          fontWeight="500"
                          color="gray.600"
                        >
                          <Text>{propertyData?.stepOne[1]?.answer} beds</Text>
                          <Text>{propertyData?.stepOne[2]?.answer} baths</Text>
                          <Text>
                            {parseInt(propertyData?.stepOne[4]?.answer)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                            sqft
                          </Text>
                        </Stack>
                        <Box w="100%">
                          <Collapse startingHeight={200} in={show}>
                            <TextRichView
                              value={
                                propertyData?.stepFour[0]?.answer
                                  ? propertyData?.stepFour[0]?.answer
                                  : 'N/A'
                              }
                            />
                          </Collapse>
                          <Button
                            leftIcon={
                              show ? (
                                <ChevronUpIcon boxSize={6} />
                              ) : (
                                <ChevronDownIcon boxSize={6} />
                              )
                            }
                            colorScheme="blue"
                            w="100%"
                            size="md"
                            onClick={handleToggle}
                            mt="1rem"
                            variant="link"
                          >
                            Show {show ? 'Less' : 'More'}
                          </Button>
                        </Box>
                      </Stack>

                      <Box>
                        <Text fontSize="2xl" fontWeight="600" mb="3">
                          Facts & Features
                        </Text>
                        <SimpleGrid
                          columns={2}
                          spacing="2"
                          fontSize="lg"
                          fontWeight="500"
                          color="gray.600"
                        >
                          <Text>
                            Year built:{' '}
                            <span style={{ color: 'black' }}>
                              {propertyData?.stepOne[5]?.answer}
                            </span>
                          </Text>
                          <Text>
                            Lot size:{' '}
                            <span style={{ color: 'black' }}>
                              {propertyData?.stepOne[6]?.answer} sqft
                            </span>
                          </Text>
                        </SimpleGrid>
                      </Box>

                      <Box>
                        <Text fontSize="2xl" fontWeight="600" mb="3">
                          Interior
                        </Text>
                        <SimpleGrid
                          columns={2}
                          spacing="2"
                          fontSize="lg"
                          fontWeight="500"
                          color="gray.600"
                        >
                          <Text>
                            Bedrooms:{' '}
                            <span style={{ color: 'black' }}>
                              {propertyData?.stepOne[1]?.answer}
                            </span>
                          </Text>
                          <Text>
                            Bathrooms:{' '}
                            <span style={{ color: 'black' }}>
                              {propertyData?.stepOne[2]?.answer}
                            </span>
                          </Text>
                          <Text>
                            Flooring size:{' '}
                            <span style={{ color: 'black' }}>
                              {parseInt(propertyData?.stepOne[4]?.answer)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                              sqft
                            </span>
                          </Text>
                          <Text>
                            Price per sq ft.:{' '}
                            <span style={{ color: 'black' }}>
                              $
                              {(homePrice / propertyData?.stepOne[4]?.answer)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </span>
                          </Text>
                        </SimpleGrid>
                      </Box>

                      <Flex direction="row" justify="flex-end">
                        <Text fontSize="sm" fontWeight="500" color="gray.400">
                          Information above is pulled from third-party services
                          and may not match public records.
                        </Text>
                      </Flex>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={{ base: 7, md: 2 }}>
                    <Card>
                      <CardBody>
                        <Stack
                          spacing="3"
                          divider={<StackDivider borderColor="gray.300" />}
                        >
                          <Box>
                            <Text
                              fontSize="lg"
                              fontWeight="500"
                              color="gray.600"
                            >
                              Home price
                            </Text>
                            <Text fontSize="3xl" fontWeight="600">
                              $
                              {homePrice
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </Text>
                          </Box>

                          <Box>
                            <Text
                              fontSize="lg"
                              fontWeight="500"
                              color="gray.600"
                            >
                              Rent
                            </Text>
                            <Text fontSize="3xl" fontWeight="600">
                              $
                              {rent
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              /mo
                            </Text>
                          </Box>

                          <Button
                            size="lg"
                            colorScheme={buttonData.color}
                            isDisabled={buttonData.disabled}
                            onClick={buttonData.handleClick}
                          >
                            {buttonData.text}
                          </Button>
                        </Stack>
                      </CardBody>
                    </Card>
                  </GridItem>
                </Grid>
              </Box>
            </>
          )}
        </Container>
        <Footer />
      </Flex>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedImage(null);
          onClose();
        }}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
          <Image
            src={selectedImage?.imageUrl}
            w="100%"
            maxH="80vh"
            objectFit="contain"
          />
          <Card>
            <CardBody>
              <Text>
                <b>Description:</b>{' '}
                {selectedImage?.imageDescription ||
                  'No description available for this image.'}
              </Text>
            </CardBody>
          </Card>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={alertIsOpen}
        onClose={() => {
          setAlertData({
            title: '',
            description: '',
          });
          alertOnClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <Card>
            <CardBody>
              <Text></Text>
            </CardBody>
          </Card>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ListingSection;
