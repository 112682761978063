import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// chakra
import { Card, CardBody, Flex, Text, Heading } from '@chakra-ui/react';

const RedirectorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let searchParams = new URLSearchParams(location.search);
  let redirectTo = searchParams.get('redirectTo');

  useEffect(() => {
    if (redirectTo) {
      navigate(`/${redirectTo}`);
    }
  }, [redirectTo]);

  return (
    <div>
      <Flex align="center" justify="center" h="100vh" w="100%" bg="gray.100">
        <Card maxW="md">
          <CardBody textAlign="center">
            <Heading mb="3">Utopia Homes</Heading>
            <Text>
              The nation's first marketplace connect landlords to rent-to-own
              tenents
            </Text>
          </CardBody>
        </Card>
      </Flex>
    </div>
  );
};

export default RedirectorPage;
