import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import AgentDashboardSidebar from '../../../Sidebar';

const Incentives = () => {
  return (
    <AgentDashboardSidebar showHeader={false}>
      <Box p={6}>
        <Box p={6} bg="white" borderRadius={12} boxShadow="lg">
          <Heading as="h2" size="lg" mb={6} textAlign={'center'}>
            Agent Incentives worth Tens of thousands of dollars
          </Heading>

          <Text fontSize="lg" mb={4} textAlign={'justify'}>
            Here’s a breakdown of the lucrative incentives available to you as a
            Utopia Agent Network (UAN) member. This is on top of the full
            commission you earn on every deal.
          </Text>

          <List spacing={6}>
            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                1. Earn $1,000 per Rent-to-Own Listing or Landlord Invitation
              </Text>
              <UnorderedList pl="4">
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Receive $1,000 for every rent-to-own property you list on
                    Utopia or for inviting landlords with rental properties.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Incentive is paid when these listings are rented by Utopia’s
                    renter/homebuyer customers.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Unlimited earning potential!
                  </Text>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                2. Earn $1,200 per Rent-to-Own Customer Invitation
              </Text>
              <UnorderedList pl="4">
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Invite your rent-to-own clients to Utopia and earn $1,200
                    for every deal when they find their dream home from Utopia’s
                    listings.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    No cap on the number of incentives you can earn!
                  </Text>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                3. Earn $600 per Invited Agent’s Deal
              </Text>
              <UnorderedList pl="4">
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Earn $600 for every deal closed by an agent you refer to
                    Utopia.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    The more agents you invite, the more you earn!
                  </Text>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                4. Earn Over $12,000 from Agent Referrals
              </Text>
              <UnorderedList pl="4">
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Refer agents to Utopia using the “Invite” feature in your
                    agent panel.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Earn 10% of the sales commission from the deals your
                    referred agents close.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    <b>Example:</b> If you Refer 10 agents and each agent closes
                    one $400,000 sale, they earn $12,000 in commission per sale
                    (3%). And, You earn $1,200 per sale, totaling <b>$12,000</b>{' '}
                    for 10 sales—all funded by Utopia!
                  </Text>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                5. Earn $25,000 for Closing 5 Sales in 12 Months
              </Text>
              <UnorderedList pl="4">
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Close five sales within a year to earn a <b>$25,000</b>{' '}
                    bonus on top of your full sales commission.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text fontSize="md" textAlign={'justify'}>
                    Achieve “Preferred Agent Status” for priority placement in
                    Utopia’s client referrals.
                  </Text>
                </ListItem>
              </UnorderedList>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Take Action Now!
              </Text>
              <Text fontSize="md" textAlign={'justify'} mb="2">
                Use the "Invite" feature in your agent panel to start earning
                these incredible rewards. You can track referrals and
                application progress in your Agent Portal.
              </Text>
              <Text fontSize="md" textAlign={'justify'} mb="2">
                For questions or additional details, feel free to reach
                out—we’re here to help!
              </Text>
            </ListItem>
          </List>

          <Flex alignItems={'center'} justifyContent={'center'} mt={10}>
            <NavLink to={'/agents/invite'}>
              <Button
                // minWidth="25vw"
                colorScheme="blue"
                backgroundColor={'#1C2430'}
                textColor={'white'}
                px={14}
                _hover={{
                  bg: 'black',
                  cursor: 'pointer',
                }}
              >
                Invite & Earn
              </Button>
            </NavLink>
          </Flex>
        </Box>
      </Box>
    </AgentDashboardSidebar>
  );
};

export default Incentives;
