import { Field, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { landlordSignUpSchema } from '../validations/LandlordSignUpSchema';

// utils
import SEO from '../utils/SEO';

// chakra
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Center,
  Card,
  CardBody,
  Show,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

const LandlordSignUp = () => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const [isDesktopScreen, setIsDesktopScreen] = useState(false);

  const toast = useToast();

  const {
    handleSignUp,
    authLoading,
    authUser,
    setAuthLoading,
    getReferralByCode,
    getInvitedUsersReferral,
  } = useAuth();

  const location = useLocation();
  const { agent } = useParams();
  let searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('email');
  let isInvited = searchParams.get('invited');

  const [referral, setReferral] = useState();

  const getAndSaveAgentInfo = async () => {
    try {
      const response = await getReferralByCode(agent);
      setReferral(response);
    } catch (err) {
      toast({
        title: 'Failed',
        description: 'Invalid Referral Code',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (agent) {
      getAndSaveAgentInfo();
    }

    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    setIsDesktopScreen(isDesktop);
  }, [isDesktop]);

  const handleSubmit = async values => {
    try {
      let agentId = agent;

      if (referral) {
        agentId = referral?.userId;
      }

      if (isInvited) {
        const referral = await getInvitedUsersReferral(values.email);
        agentId = referral?.agent;
      }

      const { error } = await handleSignUp(
        values.email?.toLowerCase(),
        values.password,
        USER_TYPES.LANDLORD,
        undefined,
        undefined,
        agentId,
        Boolean(referral),
        null
      );

      if (error) {
        console.log(error);
        toast({
          title: 'Error on Sign Up',
          description: error,
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <SEO
        title={`Landlord Sign Up`}
        description={`Landlord sign up page for Utopia Homes. Sign up to list your property on Utopia Homes.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Grid h="100vh" templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={isDesktopScreen ? 3 : 6}>
          <Container maxW="container.sm" py="8">
            <Link href="https://www.utopiahomes.us/">
              <Image
                objectFit="cover"
                w="150px"
                src="https://i.imgur.com/avkbaJm.png"
                alt="Utopia Logo"
              />
            </Link>

            <Container maxW="md" py="8">
              <Formik
                initialValues={{
                  email: isInvited ? email : '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={landlordSignUpSchema}
                onSubmit={async values => {
                  await handleSubmit(values);
                }}
              >
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing="7">
                      <Box>
                        <Heading mb="2">Sign up as a Landlord</Heading>
                        <Text fontSize="md" as="b" color="gray.600">
                          List your property on Utopia Homes.
                        </Text>
                      </Box>

                      <Show below="md">
                        <Card bg="gray.100" maxW="350px" boxShadow="lg">
                          <CardBody>
                            <Heading size="sm" mb="5">
                              To qualify, you need:
                            </Heading>
                            <Stack spacing="2">
                              <Flex align="center">
                                <CheckCircleIcon
                                  boxSize={4}
                                  mr="3"
                                  color="green.500"
                                />
                                <Text fontWeight="500" fontSize="sm">
                                  ‘Move-in’ ready Single Family Homes on Public
                                  Utilities
                                </Text>
                              </Flex>
                              <Flex align="center">
                                <CheckCircleIcon
                                  boxSize={4}
                                  mr="3"
                                  color="green.500"
                                />
                                <Text fontWeight="500" fontSize="sm">
                                  Homes with 2 or more bedrooms on less than 2
                                  acre lot
                                </Text>
                              </Flex>
                              <Flex align="center">
                                <CheckCircleIcon
                                  boxSize={4}
                                  mr="3"
                                  color="green.500"
                                />
                                <Text fontWeight="500" fontSize="sm">
                                  Homes can't have water, termite, foundation or
                                  roof damage
                                </Text>
                              </Flex>
                              <Flex align="center">
                                <CheckCircleIcon
                                  boxSize={4}
                                  mr="3"
                                  color="green.500"
                                />
                                <Text fontWeight="500" fontSize="sm">
                                  Homes must pass Inspection and Appraisal done
                                  by Utopia
                                </Text>
                              </Flex>
                            </Stack>
                          </CardBody>
                        </Card>
                      </Show>

                      <Stack spacing="7">
                        <Stack spacing="3">
                          <FormControl
                            isInvalid={
                              formik.errors.email && formik.touched.email
                            }
                          >
                            <FormLabel color="gray">Email</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="email"
                              name="email"
                              type="email"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.password && formik.touched.password
                            }
                          >
                            <FormLabel color="gray">Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="password"
                              name="password"
                              type="password"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={
                              formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                            }
                          >
                            <FormLabel color="gray">Confirm Password</FormLabel>
                            <Field
                              as={Input}
                              borderWidth="2px"
                              size="lg"
                              id="confirmPassword"
                              name="confirmPassword"
                              type="password"
                              disabled={authLoading}
                            />
                            <FormErrorMessage>
                              {formik.errors.confirmPassword}
                            </FormErrorMessage>
                          </FormControl>
                        </Stack>
                        <Button size="lg" type="submit">
                          Sign Up
                        </Button>
                      </Stack>
                      <Flex align="center" justify="center">
                        <Text fontSize="md" as="b" mr="3">
                          Already have an account?
                        </Text>
                        <Link
                          fontSize="md"
                          as="b"
                          color="blue.500"
                          onClick={() => {
                            navigate('/login');
                          }}
                        >
                          Log In
                        </Link>
                      </Flex>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Container>
          </Container>
        </GridItem>
        {isDesktopScreen && (
          <GridItem colSpan={3}>
            <Flex
              w="100%"
              h="100vh"
              backgroundImage={`url("https://images.unsplash.com/photo-1506126279646-a697353d3166?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`}
              backgroundPosition="center"
              backgroundSize="cover"
            >
              <Center w="100%">
                <Card
                  color="white"
                  backgroundColor="rgba(61, 61, 61, 0.3)"
                  backdropFilter="blur(3px)"
                  rounded="xl"
                  maxW="600px"
                >
                  <CardBody>
                    <Heading size="xl" mb="7">
                      To qualify, you need:
                    </Heading>
                    <Stack spacing="4">
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.300" />
                        <Text fontWeight="600" fontSize="lg">
                          ‘Move-in’ ready Single Family Homes on Public
                          Utilities
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.300" />
                        <Text fontWeight="600" fontSize="lg">
                          Homes with 2 or more bedrooms on less than 2 acre lot
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.300" />
                        <Text fontWeight="600" fontSize="lg">
                          Homes can't have water, termite, foundation or roof
                          damage
                        </Text>
                      </Flex>
                      <Flex align="center">
                        <CheckCircleIcon boxSize={5} mr="3" color="green.300" />
                        <Text fontWeight="600" fontSize="lg">
                          Homes must pass Inspection and Appraisal done by
                          Utopia
                        </Text>
                      </Flex>
                    </Stack>
                  </CardBody>
                </Card>
              </Center>
            </Flex>
          </GridItem>
        )}
      </Grid>
    </Fragment>
  );
};

export default LandlordSignUp;
