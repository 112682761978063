import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

// routes
import MainRoutes from './routes/Main.routes';

// components
import Loading from './components/Loading';
import { useAuth } from './context/AuthContext';
import { useCoapplicant } from './context/CoapplicantContext';

const App = () => {
  // const [isLoading, setIsLoading] = useState(false);
  const { authLoading } = useAuth();
  const { loading: coapplicantLoading } = useCoapplicant();

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="en" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <title>Utopia Homes</title>
        <meta
          name="description"
          content="Don't just rent. Do rent, earn & own."
        />
      </Helmet>
      {authLoading || coapplicantLoading ? <Loading /> : <MainRoutes />}
    </Fragment>
  );
};

export default App;
