import { Fragment, useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { USER_TYPES } from '../utils/constants';

// utils
import SEO from '../utils/SEO';

// context
import { useHomeBuyerAuth } from '../context/HomebuyerContext';

// components
import CoBuyerDocsCard from '../components/AdditionalDocuments/CoBuyerDocsCard';
import HomebuyerDocsCard from '../components/AdditionalDocuments/HomebuyerDocsCard';
import SidebarWithHeader from '../components/SidebarWithHeader';
import AddAgentCard from '../components/Agents/AddAgent/AddAgentCard';

// chakra
import { Container, Stack } from '@chakra-ui/react';

const AddAgent = () => {
  const { authUser, handleNavigation } = useAuth();
  const { applicationData } = useHomeBuyerAuth();

  const isEmailVerified = authUser.emailVerified;

  const isAgent = authUser?.data?.type == USER_TYPES.AGENT;

  const [isLargeContainer, setIsLargeContainer] = useState(false);

  const [fileSelected, setFileSelected] = useState('file1');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  return (
    <Fragment>
      <SEO
        title={`Add Agent`}
        description={`Add an agent to your account.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <SidebarWithHeader userMode="onboarding">
        <Container
          maxW={isLargeContainer == true ? 'container.md' : 'container.sm'}
        >
          <AddAgentCard />
        </Container>
      </SidebarWithHeader>
    </Fragment>
  );
};

export default AddAgent;
