import React, { Fragment, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';

// chakra
import {
  Box,
  Container,
  Text,
  Heading,
  SimpleGrid,
  Card,
  CardBody,
  Stack,
  Flex,
  StackDivider,
  Icon,
} from '@chakra-ui/react';
// chakra icons
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const ShowcaseCard = ({ id, property, images, autoPlay }) => {
  const navigate = useNavigate();

  const [selectedItem, setSelectedItem] = useState(0);

  return (
    <Fragment>
      <Card
        boxShadow="sm"
        _hover={{
          //   boxShadow: 'lg',
          bg: 'gray.200',
          cursor: 'pointer',
          //   border: '1px solid',
          //   borderColor: 'gray.300',
        }}
        onClick={() => navigate(`/listing/${id}`)}
      >
        <Carousel
          swipeable={true}
          infiniteLoop={true}
          showThumbs={false}
          autoPlay={autoPlay}
          interval={5000}
          showStatus={images.length > 1 ? true : false}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            if (index >= 5) return null; // Limit to 5 indicators

            // All circles should be the same size
            const size = 8;

            // Get the selected index from the current image modulo 5
            const selectedIndex = selectedItem % 5;
            const isHighlighted = index === selectedIndex;

            return (
              <li
                style={{
                  background: isHighlighted ? '#333' : '#bbb',
                  width: size,
                  height: size,
                  borderRadius: '50%',
                  display: 'inline-block',
                  margin: '0 4px',
                  cursor: 'pointer',
                  transition: 'background 0.3s ease',
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              />
            );
          }}
          selectedItem={selectedItem}
          onChange={index => setSelectedItem(index)}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <div
              onClick={clickHandler}
              style={{
                position: 'absolute',
                left: 15,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                background: 'rgba(255,255,255,0.9)',
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              }}
            >
              <span style={{ fontSize: '24px', lineHeight: '24px' }}>
                <ChevronLeftIcon />
              </span>
            </div>
          )}
          renderArrowNext={(clickHandler, hasNext) => (
            <div
              onClick={clickHandler}
              style={{
                position: 'absolute',
                right: 15,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 2,
                background: 'rgba(255,255,255,0.9)',
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
              }}
            >
              <span style={{ fontSize: '24px', lineHeight: '24px' }}>
                <ChevronRightIcon />
              </span>
            </div>
          )}
        >
          {images.map(image => (
            <div>
              <img
                src={image.imageUrl}
                alt={`${image?.imageDescription}`}
                style={{
                  width: '100%',
                  height: '400px',
                  objectFit: 'cover',
                }}
              />
            </div>
          ))}
        </Carousel>
        <CardBody>
          <Stack spacing="2">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              divider={<StackDivider borderColor="gray.200" />}
              mb={{ base: '2', md: '0' }}
            >
              <Flex align="center">
                <Text fontSize="lg" fontWeight="500" color="gray.500" mr="2">
                  Expected Rent:
                </Text>
                <Text fontSize="lg" fontWeight="700">
                  $
                  {property?.stepOne[3]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  /mo
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="lg" fontWeight="500" color="gray.500" mr="2">
                  Home Price:
                </Text>
                <Text fontSize="lg" fontWeight="700">
                  $
                  {property?.stepThree[0]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
              </Flex>
            </Stack>

            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[1]?.answer}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  bds
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[2]?.answer}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  ba
                </Text>
              </Flex>

              <Flex align="center">
                <Text fontSize="md" fontWeight="700" mr="1">
                  {property?.stepOne[4]?.answer
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
                <Text fontSize="md" fontWeight="500" color="gray.500">
                  sqft
                </Text>
              </Flex>
            </Stack>

            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Box>
                <Text fontSize="md" fontWeight="500" color="gray.500" mb="1">
                  {property?.stepTwo[3]?.answer}
                </Text>
                <Text fontSize="sm" fontWeight="500" color="gray.500">
                  Metro Area: {property?.stepTwo[1]?.answer},{' '}
                  {property?.stepTwo[0]?.answer}
                </Text>
              </Box>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ShowcaseCard;
