import React from 'react';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';

const TextRichView = ({ value }) => {
  return (
    <div className="ql-container ql-snow" style={{ border: 'none' }}>
      <div
        className="ql-editor"
        style={{ padding: '0px', margin: '0px' }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
      />
    </div>
  );
};

export default TextRichView;
