import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextRichView from '../components/TextRich/TextRichView';
import TechRichInput from '../components/TextRich/TechRichInput';
import {
  Box,
  Button,
  Card,
  CardBody,
  Text,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Divider,
  List,
  ListItem,
  UnorderedList,
  OrderedList,
  Container,
  Flex,
  Image,
  Link,
} from '@chakra-ui/react';

const TesterPage = () => {
  const [value, setValue] = useState('');

  return (
    <Box>
      <TechRichInput value={value} setValue={setValue} />

      <Button mt={4} onClick={() => console.log({ value })}>
        Log Value
      </Button>

      <TextRichView value={value} />
    </Box>
  );
};

export default TesterPage;
