import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import matchSorter from 'match-sorter';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useColumnOrder,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import clientImage from '../assets/icons/client.png';
import AgentInvitationButton from './Agents/AgentInvitation';
import ClientInvitationButton from './Agents/ClientInvitation';
import LandlordInvitationButton from './Agents/LandlordInvitation';
import ClientReminderButton from './Agents/ClientReminder';
import InteractionsButton from './InteractionsButton';

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

fuzzyTextFilterFn.autoRemove = val => !val;

function GenericTable({
  columns,
  data,
  loading,
  buttonTitle,
  isAgent,
  isLandlord = false,
  detailsDisabled = false,
}) {
  const navigate = useNavigate();

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0 },
    },
    useColumnOrder,
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <TableContainer>
        {data?.length > 0 ? (
          <Table
            {...getTableProps()}
            mt={5}
            maxW={'100vw'}
            colorScheme="orange"
          >
            <Thead>
              {headerGroups.map((headerGroup, i) => (
                <Tr
                  {...headerGroup.getHeaderGroupProps()}
                  backgroundColor="#e7e8e8"
                >
                  {headerGroup.headers.map(column => {
                    return (
                      <Th
                        {...column.getHeaderProps({
                          style: {},
                        })}
                      >
                        <Flex
                          {...column.getSortByToggleProps()}
                          alignItems="center"
                          justifyContent={'center'}
                        >
                          <Heading size={'xs'}>
                            {column.render('Header')}
                          </Heading>

                          {column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <TriangleDownIcon ml={2} />
                              ) : (
                                <TriangleUpIcon ml={2} />
                              )
                            ) : (
                              <Flex flexDirection="column" ml={2}>
                                <TriangleUpIcon
                                  fontSize={'0.5rem'}
                                  color="InactiveCaptionText"
                                />
                                <TriangleDownIcon
                                  fontSize={'0.5rem'}
                                  color="InactiveCaptionText"
                                />
                              </Flex>
                            )
                          ) : null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()} overflow={'auto'}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    key={i}
                    background={i % 2 === 0 ? 'transparent' : '#ffffff'}
                    height="8vh"
                  >
                    {row.cells.map((cell, index) => {
                      const isAction = cell?.column?.isAction;
                      const isInteraction = cell?.column?.isInteraction;
                      const isActionableButton =
                        cell?.column?.isActionableButton;

                      const isReminderEnabled = isAgent
                        ? cell?.row?.original?.isReminderEnabled
                        : true;

                      const client_email = encodeURIComponent(
                        cell?.row?.original?.email
                      );

                      return (
                        <Td
                          {...cell.getCellProps()}
                          alignItems="center"
                          justifyContent="center"
                          key={index}
                        >
                          <Flex alignItems="center" justifyContent={'center'}>
                            {isAction ? (
                              isReminderEnabled && (
                                <ClientReminderButton
                                  data={cell?.row?.original}
                                  title={buttonTitle}
                                  isAgent={isAgent}
                                />
                              )
                            ) : isInteraction ? (
                              <InteractionsButton data={cell?.row?.original} />
                            ) : isActionableButton ? (
                              <Button
                                onClick={() =>
                                  cell?.column?.buttonAction(
                                    cell?.row?.original
                                  )
                                }
                              >
                                {cell?.column?.buttonTitle}
                              </Button>
                            ) : (
                              <Box
                                onClick={() => {
                                  if (!detailsDisabled) {
                                    navigate(
                                      `/agents/application_details?client_email=${client_email}`
                                    );
                                  }
                                }}
                                cursor="pointer"
                              >
                                {cell.render('Cell')}
                              </Box>
                            )}
                          </Flex>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Flex
            mt={5}
            maxW={'100vw'}
            height="65vh"
            alignItems={'center'}
            justifyContent="center"
            flexDirection={'column'}
          >
            {loading ? (
              <Flex alignItems={'center'} justifyContent="center">
                <Spinner />
              </Flex>
            ) : (
              <>
                <Image src={clientImage} height={'10vh'} />
                <Text size={'md'} mt={5} mb={1}>
                  No Applications Found!
                </Text>

                {isAgent ? (
                  <AgentInvitationButton width="20vw" />
                ) : isLandlord ? (
                  <LandlordInvitationButton width="20vw" />
                ) : (
                  // <Box  maxW="40vw">
                  <ClientInvitationButton />
                  // </Box>
                )}
              </>
            )}
          </Flex>
        )}
      </TableContainer>
      {data?.length > 0 ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          width="80vw"
          p={3}
          background="rgba(255,255,255,0.8)"
          borderRadius={'10px'}
        >
          <Flex>
            <IconButton
              icon={<ArrowLeftIcon fontSize={'0.8rem'} />}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <IconButton
              ml={2}
              icon={<ChevronLeftIcon fontSize={'1.5rem'} />}
              onClick={() => previousPage()}
              isDisabled={!canPreviousPage}
            ></IconButton>
          </Flex>

          <Text fontWeight={'semibold'} mx={3}>
            Page {/* <strong> */}
            {pageIndex + 1} of {pageOptions.length}
            {/* </strong>{' '} */}
          </Text>
          {/* <Text mx={3}>| Go to page:</Text> */}
          {/* <Input
          mr={3}
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          maxW="100px"
          // style={{ maxidth: '100px' }}
        /> */}

          {/* <Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          maxW="100px"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select> */}
          <Flex>
            <IconButton
              ml={2}
              icon={<ChevronRightIcon fontSize={'1.5rem'} />}
              onClick={() => nextPage()}
              isDisabled={!canNextPage}
            ></IconButton>
            <IconButton
              ml={2}
              icon={<ArrowRightIcon fontSize={'0.8rem'} />}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            ></IconButton>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
}

function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

export default GenericTable;
