import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  Text,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Divider,
  List,
  ListItem,
  UnorderedList,
  OrderedList,
  Container,
  Flex,
  Image,
  Link,
} from '@chakra-ui/react';

const TechRichInput = ({
  value,
  setValue,
  placeholder = 'Write your content here...',
}) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  const formats = ['bold', 'italic', 'underline', 'link', 'list', 'bullet'];

  return (
    <Box
      sx={{
        '.ql-container': {
          minHeight: '200px',
          maxHeight: '400px',
          overflow: 'auto',
          borderBottomLeftRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem',
          borderWidth: '2px', // Add border width
          borderColor: 'gray.200', // Add border color
          borderTop: 'none', // Remove top border to prevent double border with toolbar
        },
        '.ql-toolbar': {
          borderTopLeftRadius: '0.375rem',
          borderTopRightRadius: '0.375rem',
          borderWidth: '2px', // Add border width
          borderColor: 'gray.200', // Add border color
        },
        '.ql-editor': {
          minHeight: '200px',
          maxHeight: '400px',
        },
      }}
    >
      <ReactQuill
        style={{
          height: 'auto',
        }}
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default TechRichInput;
