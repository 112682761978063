import { Field, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendResetEmailSchema } from '../validations/SendResetEmailSchema';

// utils
import SEO from '../utils/SEO';

// context

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';

const EmailSent = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    handleSendPasswordResetEmail,
    authLoading,
    authUser,
    setAuthLoading,
  } = useAuth();

  const [sectionStep, setSectionStep] = useState(1);
  const [sectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setAuthLoading(true);
      if (authUser) {
        navigate('/');
      }
      setAuthLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  const SubmitSection = (
    <Fragment>
      <SEO
        title={`Email Sent`}
        description={`Email sent page for Utopia Homes. Follow the instructions in the email to reset your password.`}
        name={`Utopia Homes`}
        type={`application page`}
      />

      <Heading fontSize={'2xl'} mb="6">
        Forgot your password?
      </Heading>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={sendResetEmailSchema}
        onSubmit={async values => {
          //   event.preventDefault()
          setSectionLoading(true);
          const { error, errorMessage } = await handleSendPasswordResetEmail(
            values.email
          );
          if (error) {
            if (
              errorMessage.message.toString().includes('auth/user-not-found')
            ) {
              toast({
                title: 'Email Not Found',
                description:
                  'The email you provided is not registered with Utopia Homes',
                status: 'error',
                duration: 4000,
                isClosable: true,
                position: 'top-right',
              });
            } else {
              toast({
                title: 'Error on Forget Password',
                description: error,
                status: 'error',
                duration: 4000,
                isClosable: true,
                position: 'top-right',
              });
            }
          } else {
            setSectionStep(1);
            toast({
              title: 'Reset email sent',
              description: 'Follow email instructions to reset email',
              status: 'success',
              duration: 4000,
              isClosable: true,
              position: 'top-right',
            });
          }
          setSectionLoading(false);
        }}
      >
        {formik => (
          <form
            onSubmit={e => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <Box>
              <FormControl
                isInvalid={formik.errors.email && formik.touched.email}
                mb="6"
              >
                <FormLabel>Email address</FormLabel>
                <Field
                  as={Input}
                  id="email"
                  name="email"
                  type="email"
                  disabled={authLoading}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>

              <Stack spacing={10} pb="5">
                <Button type="submit" colorScheme="blue">
                  Send reset email
                </Button>
              </Stack>

              <Flex>
                <Text>
                  Know your password?{' '}
                  <Link
                    color="blue.400"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    Log In instead
                  </Link>
                </Text>
              </Flex>
            </Box>
          </form>
        )}
      </Formik>
    </Fragment>
  );

  const ConfirmationSection = (
    <Fragment>
      <Stack align="center" textAlign="center" spacing="5">
        <Image
          boxSize="100px"
          objectFit="cover"
          src="https://i.imgur.com/0BNTHtH.png"
          alt="Dan Abramov"
        />
        <Heading fontSize={'2xl'} mb="6">
          Recovery Email Sent
        </Heading>
        <Text fontSize={'lg'}>
          We sent a password reset link to your email if there was a Utopia
          account associated with it. Please allow up to 5 minutes for the email
          to arrive.
        </Text>
        <Text fontSize={'lg'}>
          If you are unable to access your account, please contact -{' '}
          <Link
            colorScheme="blue.400"
            onClick={() => (window.location = 'mailto:support@utopia.cx')}
          >
            support@utopia.cx
          </Link>
        </Text>
      </Stack>
    </Fragment>
  );

  const LoadingSection = (
    <Fragment>
      <Flex direction="column" align="center" justify="center" minH="100vh">
        <Stack spacing="2">
          <Box>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        </Stack>
      </Flex>
    </Fragment>
  );

  const renderSwitch = index => {
    switch (index) {
      case 0:
        return SubmitSection;
      case 1:
        return ConfirmationSection;
      default:
        return SubmitSection;
    }
  };

  return (
    <Fragment>
      {sectionLoading ? (
        <LoadingSection />
      ) : (
        <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              {renderSwitch(sectionStep)}
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image
              onClick={() => {
                window.location.assign('https://www.utopiahomes.us/');
              }}
              style={{ cursor: 'pointer' }}
              alt={'Login Image'}
              objectFit={'cover'}
              src={'https://i.imgur.com/JkNbeV1.jpg'}
            />
          </Flex>
        </Stack>
      )}
    </Fragment>
  );
};

export default EmailSent;
