import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import { NavLink, useNavigate } from 'react-router-dom';
import AgentsHeader from '../../components/Agents/Header';
import { useAuth } from '../../context/AuthContext';
import SidebarLinks from './Links';
import routes from './routes';

export default function AgentDashboardSidebar({
  children,
  showHeader = true,
  hasPadding = true,
  showInvite = true,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('#F4F4F5', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="md"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>

      <MobileNav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 64 }} p={hasPadding ? '4' : 0}>
        {showHeader && <AgentsHeader showInvite={showInvite} />}

        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { authUser } = useAuth();

  const name =
    authUser?.data?.legalName?.firstName +
    ' ' +
    authUser?.data?.legalName?.lastName;

  return (
    <Box
      transition="3s ease"
      bg={'#11161D'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 64 }}
      pos="fixed"
      h="full"
      {...rest}
      // zIndex={999999}
    >
      <Flex h="20" alignItems="center" mx="8">
        <Link
          onClick={() => {
            window.location.assign('/agents/home');
          }}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <Flex align="center">
            <Image
              src="https://i.ibb.co/7n7xxFz/logo512.png"
              alt="Utopia Logo"
              maxW="40px"
            />
            <Heading size="lg" ml={2} color="white">
              Utopia
            </Heading>
          </Flex>
        </Link>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      <Flex
        direction="column"
        mb="auto"
        justifyContent="space-between"
        flex={1}
        height={'80.5%'}
      >
        <Flex
          ps="20px"
          pe={{ md: '16px', '2xl': '1px' }}
          display="flex"
          flexDirection="column"
          pt={4}
          overflowY="auto"
          marginBottom={'10vh'}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#585c61',
              borderRadius: '24px',
            },
          }}
        >
          <SidebarLinks routes={routes} />
        </Flex>

        <NavLink to="/agents/profile">
          <Card
            maxW="md"
            display={{ base: 'none', md: 'block' }}
            position="absolute"
            bottom={2}
            background={'rgba(0,0,0,0.1)'}
            left={2}
            right={2}
            // background="black"
          >
            <CardHeader>
              <Flex spacing="3">
                <Flex
                  flex="1"
                  gap="3"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Avatar name={name} src={authUser?.data?.avatar} size="md" />

                  <Box display="flex" flexDirection="column">
                    <Heading size="sm" color="white">
                      {name}
                    </Heading>
                    <Text color="white" size="xs">
                      Welcome
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </CardHeader>
          </Card>
        </NavLink>
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { authUser } = useAuth();

  const name =
    authUser?.data?.legalName?.firstName +
    ' ' +
    authUser?.data?.legalName?.lastName;
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      // bg={useColorModeValue('white', 'gray.900')}
      bg={'#11161D'}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
      display={{ base: 'flex', md: 'none' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color={'white'}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image
        src="https://i.ibb.co/7n7xxFz/logo512.png"
        alt="Utopia Logo"
        maxW="35px"
      />

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <NavLink to="/agents/profile">
              <Box py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
                <HStack>
                  <Avatar
                    size={'sm'}
                    name={name}
                    src={authUser?.data?.avatar}
                  />
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">Justina Clark</Text>
                    <Text fontSize="xs" color="gray.600">
                      Admin
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </Box>
            </NavLink>
            {/* <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider />
              <MenuItem>Sign out</MenuItem>
            </MenuList> */}
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
