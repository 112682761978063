import React, { Fragment } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  Spacer,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';

const navItems = [
  {
    type: 'link',
    label: 'Homebuyers',
    href: 'https://www.utopiahomes.us/',
    target: '_blank',
  },
  {
    type: 'link',
    label: 'Landlords',
    href: 'https://landlords.utopiahomes.us/',
    target: '_blank',
  },
  {
    type: 'link',
    label: 'Agents',
    href: 'https://www.utopiahomes.us/agents',
    target: '_blank',
  },
  {
    type: 'link',
    label: 'Search Homes',
    href: '/search-homes',
  },
  {
    type: 'dropdown',
    label: 'How It Works',
    items: [
      {
        type: 'link',
        label: 'How It Works: Homebuyers',
        href: 'https://www.utopiahomes.us/how-it-works',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'How It Works: Landlords',
        href: 'https://landlords.utopiahomes.us/how-it-works',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'How It Works: Investors',
        href: 'https://investors.utopiahomes.us/how-it-works',
        target: '_blank',
      },
    ],
  },
  {
    type: 'dropdown',
    label: 'About',
    items: [
      {
        type: 'link',
        label: 'About Us',
        href: 'https://www.utopiahomes.us/about-us',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'Partners: Investor',
        href: 'https://investors.utopiahomes.us/',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'Partners: Lenders',
        href: 'https://www.utopiahomes.us/lenders',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'FAQ: Investor',
        href: '/faq',
      },
      {
        type: 'link',
        label: 'FAQ: Homebuyer',
        href: 'https://www.utopiahomes.us/faq/general-questions',
        target: '_blank',
      },
      {
        type: 'link',
        label: 'Blogs: Investor',
        href: '/blogs',
      },
      {
        type: 'link',
        label: 'Blogs: Homebuyer',
        href: 'https://blog.utopiahomes.us/',
        target: '_blank',
      },
    ],
  },
];

// Helper components for navigation links
const NavLink = ({ href, target, children }) => (
  <Link href={href} target={target}>
    <Button variant="link" mr="7" color="gray.700">
      <Text fontSize="sm" fontWeight="600">
        {children}
      </Text>
    </Button>
  </Link>
);

const DrawerNavLink = ({ href, target, children }) => (
  <Link href={href} target={target}>
    <Button variant="ghost">
      <Box w="100%" textAlign="left">
        <Text fontSize="md" fontWeight="600">
          {children}
        </Text>
      </Box>
    </Button>
  </Link>
);

// Main Navbar component
const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { authUser } = useAuth();
  const navigate = useNavigate();

  return (
    <Fragment>
      <Container maxW="container.xl">
        <Flex py="5" align="center">
          <Link href="https://www.utopiahomes.us/" target="_blank">
            <Image
              src="https://i.imgur.com/avkbaJm.png"
              maxW="130px"
              _hover={{ cursor: 'pointer' }}
            />
          </Link>

          <Spacer />

          <Flex align="center" display={{ base: 'none', md: 'block' }}>
            {navItems.map(item =>
              item.type === 'link' ? (
                <NavLink key={item.href} href={item.href} target={item.target}>
                  {item.label}
                </NavLink>
              ) : (
                <Menu key={item.label}>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    variant="link"
                    mr="5"
                    color="gray.700"
                    _active={{ color: 'gray.500' }}
                  >
                    <Text fontSize="sm" fontWeight="600">
                      {item.label}
                    </Text>
                  </MenuButton>
                  <MenuList color="gray.600">
                    {item.items.map(subItem => (
                      <Link
                        key={subItem.href}
                        href={subItem.href}
                        target={subItem.target}
                      >
                        <MenuItem>{subItem.label}</MenuItem>
                      </Link>
                    ))}
                  </MenuList>
                </Menu>
              )
            )}
          </Flex>

          {authUser ? (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                navigate('/');
              }}
            >
              Dashboard
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                navigate('/accounts');
              }}
            >
              Sign In
            </Button>
          )}

          <IconButton
            display={{ base: 'block', md: 'none' }}
            aria-label="menu"
            icon={<HamburgerIcon />}
            size="sm"
            ml="4"
            variant="outline"
            colorScheme="white"
            onClick={onOpen}
          />
        </Flex>
      </Container>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>

          <DrawerBody>
            <Stack
              direction="column"
              divider={<StackDivider borderColor="gray.200" />}
              spacing={4}
              align="stretch"
            >
              {navItems.map(item =>
                item.type === 'link' ? (
                  <DrawerNavLink
                    key={item.href}
                    href={item.href}
                    target={item.target}
                  >
                    {item.label}
                  </DrawerNavLink>
                ) : (
                  <Accordion
                    key={item.label}
                    allowToggle
                    borderColor="transparent"
                  >
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box w="100%" textAlign="left">
                            <Text fontSize="md" fontWeight="600">
                              {item.label}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Stack
                          direction="column"
                          divider={<StackDivider borderColor="gray.200" />}
                          spacing={3}
                          align="stretch"
                        >
                          {item.items.map(subItem => (
                            <DrawerNavLink
                              key={subItem.href}
                              href={subItem.href}
                              target={subItem.target}
                            >
                              {subItem.label}
                            </DrawerNavLink>
                          ))}
                        </Stack>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                )
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
};

export default Navbar;
