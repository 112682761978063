import React, { Fragment, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// context
import { useAgentAuth } from '../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../Sidebar';
import GenericTable from '../../../components/GenericTable';

// chakra
import {
  Container,
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
} from '@chakra-ui/react';

const LandlordProperties = ({ type }) => {
  const navigate = useNavigate();

  const {
    searchResults,
    getAgentInvitedLandlordProperties,
    invitedLandlordProperties,
  } = useAgentAuth();

  const [status, setStatus] = useState(
    type === 'started'
      ? 'Started'
      : type === 'in_review'
      ? 'In Review'
      : type === 'accepted'
      ? 'Accepted'
      : type === 'rejected'
      ? 'Rejected'
      : ''
  );
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);

  const columns = React.useMemo(() => {
    return status === 'Started'
      ? [
          {
            Header: 'Property Address',
            accessor: 'propertyAddress',
            minWidth: 150,
          },
          {
            Header: 'Email',
            accessor: 'email',
            minWidth: 150,
            // disableSortBy: true,
          },

          {
            Header: 'Started At',
            accessor: 'timeStarted',
            minWidth: 150,

            Cell: ({ value }) => moment(value).fromNow(),
            sortType: (a, b) => {
              const dateA = a.values.timeStarted
                ? new Date(a.values.timeStarted)
                : undefined;
              const dateB = b.values.timeStarted
                ? new Date(b.values.timeStarted)
                : undefined;
              return dateA && dateB ? dateA - dateB : 0;
            },
          },
          {
            Header: 'Property Status',
            accessor: 'status',
            minWidth: 150,
            // disableSortBy: true,
          },
          {
            Header: 'Actions',
            accessor: 'id',
            minWidth: 150,
            disableSortBy: true,
            isActionableButton: true,
            buttonTitle: 'View',
            buttonAction: value => {
              navigate(`/landlord/property-details/${value?.id}/agent`);
            },
          },
        ]
      : [
          {
            Header: 'Property Address',
            accessor: 'propertyAddress',
            minWidth: 150,
          },
          {
            Header: 'Email',
            accessor: 'email',
            minWidth: 150,
            // disableSortBy: true,
          },

          {
            Header: 'Time Submitted',
            accessor: 'timeSubmitted',
            minWidth: 150,

            Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
            sortType: (a, b) => {
              const dateA = a.values.timeSubmitted
                ? new Date(a.values.timeSubmitted)
                : undefined;
              const dateB = b.values.timeSubmitted
                ? new Date(b.values.timeSubmitted)
                : undefined;
              return dateA && dateB ? dateA - dateB : 0;
            },
          },
          {
            Header: 'Property Status',
            accessor: 'status',
            minWidth: 150,
            // disableSortBy: true,
          },
          {
            Header: 'Actions',
            accessor: 'id',
            minWidth: 150,
            disableSortBy: true,
            isActionableButton: true,
            buttonTitle: 'View',
            buttonAction: value => {
              navigate(`/landlord/property-details/${value?.id}/agent`);
            },
          },
        ];
  }, [status]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      setStatus(
        type === 'started'
          ? 'Started'
          : type === 'in_review'
          ? 'In Review'
          : type === 'accepted'
          ? 'Accepted'
          : type === 'rejected'
          ? 'Rejected'
          : ''
      );

      const data = await getAgentInvitedLandlordProperties();

      let filteredData = [];
      for (let i = 0; i < data.length; i++) {
        const property = data[i];

        if (type === 'started') {
          if (property.status === 'INCOMPLETE') {
            filteredData.push(property);
          }
        } else if (type === 'in_review') {
          if (property.status === 'IN_REVIEW') {
            filteredData.push(property);
          }
        } else if (type === 'accepted') {
          if (property.status === 'ACCEPTED') {
            filteredData.push(property);
          }
        } else if (type === 'rejected') {
          if (property.status === 'REJECTED') {
            filteredData.push(property);
          }
        }
      }

      let cleanData = [];
      for (let i = 0; i < filteredData.length; i++) {
        const property = filteredData[i];

        cleanData.push({
          id: property?.id,
          email: property?.email,
          status: property?.status,
          timeStarted: property?.timeStarted,
          timeSubmitted: property?.timeSubmitted,
          timeStartedFormatted: property?.timeStartedFormatted,
          timeSubmittedFormatted: property?.timeSubmittedFormatted,
          propertyAddress: property?.stepTwo[3]?.answer,
        });
      }

      if (type === 'started') {
        cleanData.sort((a, b) => {
          if (!a.timeStarted || !b.timeStarted) return 0;
          return b.timeStarted - a.timeStarted;
        });
      } else if (
        type === 'in_review' ||
        type === 'accepted' ||
        type === 'rejected'
      ) {
        cleanData.sort((a, b) => {
          if (!a.timeSubmitted || !b.timeSubmitted) return 0;
          return b.timeSubmitted - a.timeSubmitted;
        });
      }

      setPropertyData(cleanData);

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [type]);

  console.log({ status });

  return (
    <Fragment>
      <AgentDashboardSidebar showHeader={false}>
        <Container maxW="container.2xl">
          <Flex direction={'column'}>
            <Box mt={4} mb={8}>
              <Text fontSize={'2xl'} fontWeight={'bold'}>
                Landlord Properties: {status} ({propertyData.length})
              </Text>
            </Box>

            {loading ? (
              <Flex justifyContent={'center'}>
                <Spinner />
              </Flex>
            ) : (
              <GenericTable
                columns={columns}
                data={propertyData}
                loading={loading}
                isAgent={false}
                isLandlord={true}
                detailsDisabled={true}
              />
            )}
          </Flex>
        </Container>
      </AgentDashboardSidebar>
    </Fragment>
  );
};

export default LandlordProperties;
