import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { MultiSelectTheme } from 'chakra-multiselect';
import './index.css';

// providers
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AuthProvider } from './context/AuthContext';
import { AgentAuthProvider } from './context/AgentContext';
import { HomeBuyerAuthProvider } from './context/HomebuyerContext';
import { CoapplicantProvider } from './context/CoapplicantContext';
import { LandlordProvider } from './context/LandlordContext';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

const theme = extendTheme({
  components: {
    MultiSelect: MultiSelectTheme,
  },
});

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AuthProvider>
          <HomeBuyerAuthProvider>
            <AgentAuthProvider>
              <CoapplicantProvider>
                <LandlordProvider>
                  <ColorModeScript />
                  <App />
                </LandlordProvider>
              </CoapplicantProvider>
            </AgentAuthProvider>
          </HomeBuyerAuthProvider>
        </AuthProvider>
      </ChakraProvider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
