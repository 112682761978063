import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import html2pdf from 'html2pdf.js';

// context
import { useAuth } from '../../../../context/AuthContext';
import { useAgentAuth } from '../../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../../Sidebar';

// chakra
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  StackDivider,
  Container,
  Divider,
} from '@chakra-ui/react';

const formatPhoneNumber = phoneNumber => {
  // Remove all non-digit characters from the phone number
  const digitsOnly = phoneNumber?.replace(/\D/g, '');

  // Check if the phone number is already formatted with dashes
  if (digitsOnly?.length === 10) {
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  // If the phone number is not already formatted, add dashes after every 3 digits
  const formattedNumber = digitsOnly?.replace(
    /(\d{3})(\d{3})(\d+)/,
    '$1-$2-$3'
  );

  return formattedNumber;
};

const Marketing = () => {
  const [referralCode, setReferralCode] = useState('');
  const ref = useRef(null);

  const { authUser, getCurrentUserReferralCode } = useAuth();
  const { applicationData } = useAgentAuth();

  const name =
    authUser?.data?.legalName?.firstName +
    ' ' +
    authUser?.data?.legalName?.lastName;

  const contactNo = applicationData?.stepOne[0]?.answer;

  const brokerage = applicationData?.stepOne[2].answer;

  const getReferral = async () => {
    try {
      const response = await getCurrentUserReferralCode();
      setReferralCode(response);
    } catch (err) {
      console.log('🚀 ~ file: index.js:79 ~ getReferral ~ err:', err);
    }
  };

  const baseOptions = {
    margin: 0,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: {
      scale: 2,
      useCORS: true,
      allowTaint: true,
    },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
  };

  const generateOutput = type => {
    const element = ref.current;

    setTimeout(() => {
      const generator = html2pdf().set(baseOptions).from(element);

      if (type === 'pdf') {
        generator
          .toPdf()
          .get('pdf')
          .then(pdf => {
            pdf.setProperties({
              title: `${name}_Client_Marketing_Material`,
            });
            const blobUrl = URL.createObjectURL(pdf.output('blob'));
            window.open(blobUrl);
          });
      } else {
        generator.outputImg().then(img => {
          const link = document.createElement('a');
          link.href = img.src;
          link.download = `${name}_Client_Marketing_Material.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    }, 500);
  };

  const onGeneratePdf = () => generateOutput('pdf');
  const onGenerateImage = () => generateOutput('image');

  useEffect(() => {
    getReferral();
  }, []);

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Flex w="100%" align="center" justify="center" flexDirection="column">
        <Flex w="100%" align="center" justify="center">
          <Box
            overflowX="auto"
            overflowY="auto"
            maxW="100vw"
            maxH={{ base: '90vh', md: 'auto' }}
            p={{ base: 2, md: 0 }}
          >
            <Flex
              ref={ref}
              w="1056px"
              h="815px"
              align="center"
              justify="center"
              bg="white"
            >
              <Box w="100%" p="4">
                <Stack
                  direction="row"
                  w="100%"
                  divider={
                    <StackDivider borderColor="gray.200" borderWidth="3px" />
                  }
                >
                  <Flex align="center" justify="center" maxW="400px">
                    <Container>
                      <Flex direction="column" gap="8" mb="4">
                        <Box>
                          <Text fontSize="3xl" fontWeight="bold" mb="4">
                            Utopia Homes
                          </Text>
                          <Flex direction="column" gap="2">
                            <Text fontSize="md" fontWeight="semibold">
                              Looking to own a home but not able to get a
                              mortgage?
                            </Text>
                            <Text fontSize="md" fontWeight="semibold">
                              Utopia can help! Our innovative "Rent, Earn, and
                              Own" program is designed to make homeownership
                              accessible to everyone.
                            </Text>
                          </Flex>
                        </Box>

                        <Box>
                          <Text fontSize="xl" fontWeight="bold" mb="4">
                            How It Works
                          </Text>
                          <Stack>
                            <Text fontSize="md">
                              <b>Get Approved:</b> Apply for Free in less than 3
                              mins to find your Home buying budget. No impact on
                              your credit score and non-binding to you.
                            </Text>
                            <Text fontSize="md">
                              <b>Find your Utopia:</b> Pick your dream home
                              (with our agents or yours). We will buy it with an
                              all-cash offer.
                            </Text>
                            <Text fontSize="md">
                              <b>Move-In and Earn:</b> Rent your home while we
                              work with you to make you a Homeowner. Our program
                              turbo-charges your monthly savings for Down
                              Payment by sharing the gains from Home Price
                              Appreciation with you.
                            </Text>
                          </Stack>
                        </Box>
                      </Flex>
                    </Container>
                  </Flex>

                  <Flex w="100%" align="center" justify="center">
                    <Container maxW="100%">
                      <Flex>
                        <Box w="100px" h="450px" bg="red" zIndex="100" />
                        <Box ml="-40px" w="100%">
                          <Flex justify="right">
                            <Image
                              src="https://i.imgur.com/avkbaJm.png"
                              alt="Utopia Homes Logo"
                              maxW="150px"
                              w="100%"
                              mb="4"
                            />
                          </Flex>

                          <Box
                            position="relative"
                            width="100%"
                            height="440px"
                            overflow="hidden"
                            borderRadius="md"
                            mb="4"
                          >
                            <Image
                              position="absolute"
                              top="50%"
                              left="50%"
                              transform="translate(-50%, -50%)"
                              src={
                                'https://images.unsplash.com/photo-1588111817213-1a12f73fbf20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2274&q=80'
                              }
                              alt="Property Hero Image"
                              minW="100%"
                              minH="100%"
                              style={{
                                objectFit: 'cover',
                              }}
                            />
                          </Box>

                          <Flex justify="space-between" mb="4">
                            <Box mr="4">
                              <Text fontSize="2xl" fontWeight="bold" mb="2">
                                {name}
                              </Text>
                              <Stack spacing="2">
                                <Flex>
                                  <Text fontSize="lg" fontWeight="semibold">
                                    💼 {brokerage}
                                  </Text>
                                </Flex>
                                <Flex>
                                  <Text fontSize="lg" fontWeight="semibold">
                                    ✉️ {authUser.email}
                                  </Text>
                                </Flex>
                                <Flex>
                                  <Text fontSize="lg" fontWeight="semibold">
                                    📱 {formatPhoneNumber(contactNo)}
                                  </Text>
                                </Flex>
                              </Stack>
                            </Box>

                            <Box>
                              <Box
                                height="auto"
                                margin="0 auto"
                                maxWidth="150"
                                width="100%"
                              >
                                <QRCode
                                  size={150}
                                  style={{
                                    height: 'auto',
                                    maxWidth: '100%',
                                    width: '100%',
                                  }}
                                  value={`https://dash.utopiahomes.us/c/${referralCode?.code}`}
                                  viewBox={`0 0 256 256`}
                                />
                              </Box>
                            </Box>
                          </Flex>
                        </Box>
                      </Flex>
                    </Container>
                  </Flex>
                </Stack>
                <Divider borderColor="gray.200" borderWidth="3px" />
                <Flex w="100%" justify="center" align="center" gap="8" py="4">
                  <Flex align="center">
                    <Text mr="2">🌐</Text>
                    <Text>www.utopiahomes.us</Text>
                  </Flex>
                  <Flex align="center">
                    <Text mr="2">📨</Text>
                    <Text>support@utopia.cx</Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>

        <Flex alignItems={'center'} justifyContent={'center'} mt="4">
          <Button
            minW={20}
            fontSize="sm"
            px={'5'}
            variant="outline"
            // onClick={handleDownloadClick}
            // onClick={() => handleMarketingMaterialDownload(true)}
            onClick={onGenerateImage}
            mr={2}
            backgroundColor={'#1C2430'}
            textColor={'white'}
            _hover={{
              bg: 'black',
              cursor: 'pointer',
            }}
          >
            Download Image
          </Button>

          <Button
            minW={20}
            fontSize="sm"
            px={'5'}
            variant="outline"
            onClick={onGeneratePdf}
            backgroundColor={'#1C2430'}
            textColor={'white'}
            _hover={{
              bg: 'black',
              cursor: 'pointer',
            }}
          >
            Download Pdf
          </Button>
        </Flex>
      </Flex>
    </AgentDashboardSidebar>
  );
};

export default Marketing;
