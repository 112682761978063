import { Navigate, Route, Routes } from 'react-router-dom';

// pages
import Accounts from '../pages/Accounts.page';
import AgentSignUp from '../pages/AgentSignUp.page';
import AuthAction from '../pages/AuthAction.page';
import CoapplicantOnboarding from '../pages/CoapplicantOnboarding.page';
import EmailSent from '../pages/EmailSent';
import HomebuyerDocuments from '../pages/HomebuyerDocuments.page';
import HomebuyerSignUp from '../pages/HomebuyerSignUp.page';
import LogIn from '../pages/LogIn.page';
import Onboarding from '../pages/Onboarding.page';
import ResetPassword from '../pages/ResetPassword.page';
import PrivateRoute from './Private.routes';
import EmailUnsubscribe from '../pages/EmailUnsubscribe.page';
import AddAgent from '../pages/AddAgent.page';
import LandlordSignUp from '../pages/LandlordSignUp.page';
import LandlordOnboarding from '../pages/LandlordOnboarding.page';
import SearchHomes from '../pages/SearchHomes.page';
import Listing from '../pages/Listing.page';
import TesterPage from '../pages/Tester.page';
import RedirectorPage from '../pages/Redirector.page';

// landlord dashboard
import LandlordHome from '../LandlordDashboard/Views/LandlordHome';
import AddProperty from '../LandlordDashboard/Views/AddProperty';
import LandlordPropertyDetails from '../LandlordDashboard/Views/LandlordPropertyDetails';

// agent dashboard
import AgentHome from '../AgentDashboard/Views/AgentHome';
import AllClients from '../AgentDashboard/Views/AllClients';
import ApplicationDetails from '../AgentDashboard/Views/ApplicationDetails';
import Applications from '../AgentDashboard/Views/Applications';
import ApplicationStarted from '../AgentDashboard/Views/Applications/ApplicationStarted';
import ConditionallyApproved from '../AgentDashboard/Views/Applications/ConditionallyApproved';
import FullyApproved from '../AgentDashboard/Views/Applications/FullyApproved';
import Invited from '../AgentDashboard/Views/Applications/Invited';
import Prequalified from '../AgentDashboard/Views/Applications/Prequalified';
import Closings from '../AgentDashboard/Views/Closings';
import InspectionComplete from '../AgentDashboard/Views/Closings/InspectionComplete';
import OfferClosed from '../AgentDashboard/Views/Closings/OfferClosed';
import PreclosingComplete from '../AgentDashboard/Views/Closings/PreclosingComplete';
import InActive from '../AgentDashboard/Views/InActive';
import Paused from '../AgentDashboard/Views/InActive/Paused';
import Rejected from '../AgentDashboard/Views/InActive/Rejected';
import Invite from '../AgentDashboard/Views/Invite';
import AgentInviteListing from '../AgentDashboard/Views/Invite/AgentInviteListing';
import Offers from '../AgentDashboard/Views/Offers';
import InitiateOffer from '../AgentDashboard/Views/Offers/InitiateOffer';
import InitiatedOffers from '../AgentDashboard/Views/Offers/InitiatedOffers';
import OfferAccepted from '../AgentDashboard/Views/Offers/OfferAccepted';
import OfferExtended from '../AgentDashboard/Views/Offers/OfferExtended';
import Profile from '../AgentDashboard/Views/Profile';
import ContactUs from '../AgentDashboard/Views/resources/ContactUs';
import Help from '../AgentDashboard/Views/resources/Help';
import Incentives from '../AgentDashboard/Views/resources/Incentives';
import HomebuyerInvite from '../pages/HomebuyerInvite.page';
import Marketing from '../AgentDashboard/Views/resources/Marketing';
import LandlordMarketing from '../AgentDashboard/Views/resources/Marketing/Landlord';
import MoreInfoRequired from '../AgentDashboard/Views/Applications/MoreInfoRequired';
import Inreview from '../AgentDashboard/Views/Applications/Inreview';
import Properties from '../AgentDashboard/Views/Properties';
import AgentsAddProperty from '../AgentDashboard/Views/Properties/AddProperties';
import LandlordInvite from '../AgentDashboard/Views/LandlordInvite';
import LandlordProperties from '../AgentDashboard/Views/LandlordProperties';

const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />

      <Route path="/redirector" element={<RedirectorPage />} />

      <Route
        path="/documents"
        element={
          <PrivateRoute>
            <HomebuyerDocuments />
          </PrivateRoute>
        }
      />

      <Route
        path="/add-agent"
        element={
          <PrivateRoute>
            <AddAgent />
          </PrivateRoute>
        }
      />

      <Route
        path="/homebuyer-invite"
        element={
          <PrivateRoute>
            <HomebuyerInvite />
          </PrivateRoute>
        }
      />

      {/* <Route
        path="/application/status"
        element={
          <PrivateRoute>
            <HomebuyerApplicationStatus />
          </PrivateRoute>
        }
      /> */}

      {/* auth */}
      <Route path="/login" element={<LogIn />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route path="/signup/homebuyer" element={<HomebuyerSignUp />} />
      <Route path="/signup/landlord" element={<LandlordSignUp />} />
      <Route path="/signup/agent" element={<AgentSignUp />} />
      <Route path="/account/reset_password" element={<ResetPassword />} />
      <Route path="/email_sent" element={<EmailSent />} />
      <Route path="/auth_action" element={<AuthAction />} />
      <Route path="/email/unsub/:id" element={<EmailUnsubscribe />} />

      {/* Referrals */}
      <Route path="/c/:agent" element={<HomebuyerSignUp />} />
      <Route path="/a/:agent" element={<AgentSignUp />} />
      <Route path="/l/:agent" element={<LandlordSignUp />} />

      {/* landlord dashboard */}
      <Route
        path="/landlord/home"
        element={
          <PrivateRoute>
            <LandlordHome />
          </PrivateRoute>
        }
      />
      <Route
        path="/landlord/add-property/:id"
        element={
          <PrivateRoute>
            <AddProperty />
          </PrivateRoute>
        }
      />
      <Route
        path="/landlord/property-details/:id/:userType"
        element={
          <PrivateRoute>
            <LandlordPropertyDetails />
          </PrivateRoute>
        }
      />

      {/* agent dashboard */}
      <Route
        path="/agents/home"
        element={
          <PrivateRoute>
            <AgentHome />
          </PrivateRoute>
        }
      />
      <Route
        path="/agents/clients"
        element={
          <PrivateRoute>
            <AllClients />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications"
        element={
          <PrivateRoute>
            <Applications />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/closings"
        element={
          <PrivateRoute>
            <Closings />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/offers"
        element={
          <PrivateRoute>
            <Offers />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/invited"
        element={
          <PrivateRoute>
            <Invited />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/application_started"
        element={
          <PrivateRoute>
            <ApplicationStarted />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/prequalified"
        element={
          <PrivateRoute>
            <Prequalified />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/conditionally_approved"
        element={
          <PrivateRoute>
            <ConditionallyApproved />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/fully_approved"
        element={
          <PrivateRoute>
            <FullyApproved />
          </PrivateRoute>
        }
      />

      {/* Offers */}

      <Route
        path="/agents/offers/offer_accepted"
        element={
          <PrivateRoute>
            <OfferAccepted />
          </PrivateRoute>
        }
      />
      <Route
        path="/agents/offers/offer_extended"
        element={
          <PrivateRoute>
            <OfferExtended />
          </PrivateRoute>
        }
      />
      {/* Closings */}

      <Route
        path="/agents/closings/offer_closed"
        element={
          <PrivateRoute>
            <OfferClosed />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/closings/preclosing_complete"
        element={
          <PrivateRoute>
            <PreclosingComplete />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/closings/inspection_complete"
        element={
          <PrivateRoute>
            <InspectionComplete />
          </PrivateRoute>
        }
      />
      <Route
        path="/agents/application_details"
        element={
          <PrivateRoute>
            <ApplicationDetails />
          </PrivateRoute>
        }
      />

      {/* Inactive */}
      <Route
        path="/agents/inactive"
        element={
          <PrivateRoute>
            <InActive />
          </PrivateRoute>
        }
      />
      <Route
        path="/agents/rejected"
        element={
          <PrivateRoute>
            <Rejected />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/paused"
        element={
          <PrivateRoute>
            <Paused />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/invite"
        element={
          <PrivateRoute>
            <Invite />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/invites"
        element={
          <PrivateRoute>
            <AgentInviteListing />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/faq"
        element={
          <PrivateRoute>
            <Help />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/incentives"
        element={
          <PrivateRoute>
            <Incentives />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/contact_us"
        element={
          <PrivateRoute>
            <ContactUs />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/marketing"
        element={
          <PrivateRoute>
            <Marketing />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/marketing"
        element={
          <PrivateRoute>
            <LandlordMarketing />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/initiate_offer"
        element={
          <PrivateRoute>
            <InitiateOffer />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/offers/initiated"
        element={
          <PrivateRoute>
            <InitiatedOffers />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/more_info"
        element={
          <PrivateRoute>
            <MoreInfoRequired />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/applications/inreview"
        element={
          <PrivateRoute>
            <Inreview />
          </PrivateRoute>
        }
      />

      <Route path="/agents" element={<Navigate to="/agents/home" replace />} />

      <Route
        path="/agents/properties"
        element={
          <PrivateRoute>
            <Properties />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/properties/add-property/:id"
        element={
          <PrivateRoute>
            <AgentsAddProperty />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/invites"
        element={
          <PrivateRoute>
            <LandlordInvite />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/properties/started"
        element={
          <PrivateRoute>
            <LandlordProperties type="started" />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/properties/in_review"
        element={
          <PrivateRoute>
            <LandlordProperties type="in_review" />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/properties/rejected"
        element={
          <PrivateRoute>
            <LandlordProperties type="rejected" />
          </PrivateRoute>
        }
      />

      <Route
        path="/agents/landlord/properties/accepted"
        element={
          <PrivateRoute>
            <LandlordProperties type="accepted" />
          </PrivateRoute>
        }
      />

      <Route
        path="/coapplicant/onboarding/:id"
        element={<CoapplicantOnboarding />}
      />

      <Route path="/landlord/onboarding" element={<LandlordOnboarding />} />

      <Route path="/search-homes" element={<SearchHomes />} />
      <Route path="/listing/:id" element={<Listing />} />
      <Route path="/tester" element={<TesterPage />} />
    </Routes>
  );
};

export default MainRoutes;
