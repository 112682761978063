import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

// context
import { useAgentAuth } from '../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../Sidebar';
import GenericTable from '../../../components/GenericTable';

// chakra
import { CardBody, Flex, Stack, Text, Card, Box } from '@chakra-ui/react';

const LandlordInvite = () => {
  const { getLandlordsInvitesData, invitedLandlords } = useAgentAuth();
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        minWidth: 150,
        // disableSortBy: true,
      },
      {
        Header: 'Email',
        accessor: 'email',
        minWidth: 150,
        // disableSortBy: true,
      },
      {
        Header: 'Invite Sent',
        accessor: 'createdAt',
        minWidth: 150,
        Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
        sortType: (a, b) => {
          const dateA = a.values.createdAt?.seconds
            ? new Date(a.values.createdAt.seconds * 1000)
            : undefined;
          const dateB = b.values.createdAt?.seconds
            ? new Date(b.values.createdAt.seconds * 1000)
            : undefined;
          return dateA && dateB ? dateA.getTime() - dateB.getTime() : 0;
        },
      },
      // {
      //   Header: 'Last login',
      //   accessor: 'lastLogin',
      //   minWidth: 150,
      //   // disableSortBy: true,
      // },
      {
        Header: 'Status',
        accessor: 'applicationStatus',
        minWidth: 150,
        // disableSortBy: true,
      },
      // // {
      // //   Header: 'Notes',
      // //   accessor: 'interaction',
      // //   minWidth: 150,
      // //   disableSortBy: true,
      // //   isInteraction: true,
      // // },
      // {
      //   Header: 'Actions',
      //   accessor: 'action',
      //   minWidth: 150,
      //   disableSortBy: true,
      //   isAction: true,
      // },
    ],
    []
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const dataRaw = await getAgentLandlordReferrals();
  //       let data = dataRaw?.map((item, index) => ({
  //         index: index + 1,
  //         clientEmail: item?.clientEmail,
  //         status:
  //           item?.status &&
  //           item?.status.charAt(0).toUpperCase() + item?.status.slice(1),
  //         createdAt: item?.createdAt,
  //       }));
  //       console.log(dataRaw);
  //       setReferrals(data);
  //       setLoading(false);
  //     } catch (err) {
  //       console.log(err);
  //       setReferrals([]);
  //       setLoading(false);
  //     }
  //   };

  //   fetchData().catch(console.error);
  // }, []);

  useEffect(() => {
    setLoading(true);

    getLandlordsInvitesData(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Fragment>
      <AgentDashboardSidebar showInvite={true}>
        <Box>
          <Text fontSize="xl" fontWeight="bold">
            Invited Landlords
          </Text>

          <GenericTable
            columns={columns}
            data={invitedLandlords}
            loading={loading}
            isAgent={false}
            isLandlord={true}
            detailsDisabled={true}
          />
        </Box>
      </AgentDashboardSidebar>
    </Fragment>
  );
};

export default LandlordInvite;
